<template>
  <div>
    <div class="columns is-multiline">
      <div
        class="column is-4 has-text-centered is-shadowless"
        style="border: 1px solid #dedede;"
      >
        <h1 class="is-size-3">
          Active users
        </h1>
        <h3 class="is-size-1">
          {{ activeUsers }}
        </h3>
      </div>
      <div
        class="column is-4 has-text-centered is-shadowless"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Total users
        </h1>
        <h3 class="is-size-1">
          {{ totalNumber }}
        </h3>
      </div>
      <div
        class="column is-4 has-text-centered is-shadowless"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Conversion rate
        </h1>
        <h3 class="is-size-1">
          {{ conversionRate }}%
        </h3>
      </div>
      <div
        class="column is-6 has-text-centered"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Popular Tariffs
        </h1>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(tariff, index) in popularTariffs"
              :key="index"
            >
              <td>{{ tariff.tariff.title }}</td>
              <td>{{ tariff.tariff.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="column is-6 has-text-centered"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Average time spent on page
        </h1>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Page</th>
              <th>Time in seconds</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(page, index) in pages"
              :key="index"
            >
              <td>{{ page.page }}</td>
              <td>{{ page.round }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="column is-4 has-text-centered"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Left while on page
        </h1>
        <Pie :chart-data="dataLastPage" />
      </div>
      <div
        class="column is-4 has-text-centered"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Countries
        </h1>
        <Pie :chart-data="dataCountry" />
      </div>
      <div
        class="column is-4 has-text-centered"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Cities
        </h1>
        <Pie :chart-data="dataCities" />
      </div>
      <div
        class="column is-4 has-text-centered"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Devices
        </h1>
        <Pie :chart-data="dataDevices" />
      </div>
      <div
        class="column is-4 has-text-centered"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Operating Systems
        </h1>
        <Pie :chart-data="dataOS" />
      </div>
      <div
        class="column is-4 has-text-centered"
        style="border: 1px solid #dedede"
      >
        <h1 class="is-size-3">
          Browsers
        </h1>
        <Pie :chart-data="dataBrowsers" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Pie from './charts/Pie';
export default  {
    components: {
        Pie,
    },
    data: () => ({
        dataCountry: {
            labels: [],
            datasets: [
                {
                    backgroundColor: ['#d7d6fe', '#fee4e1', '#c5f9f6', '#DD1B16'],
                    data: []
                }
            ]
        },
        dataCities: {
            labels: [],
            datasets: [
                {
                    backgroundColor: ['#d7d6fe', '#fee4e1', '#c5f9f6', '#DD1B16'],
                    data: []
                }
            ]
        },
        dataDevices: {
            labels: [],
            datasets: [
                {
                    backgroundColor: ['#d7d6fe', '#fee4e1', '#c5f9f6', '#DD1B16'],
                    data: []
                }
            ]
        },
        dataOS: {
            labels: [],
            datasets: [
                {
                    backgroundColor: ['#d7d6fe', '#fee4e1', '#c5f9f6', '#DD1B16'],
                    data: []
                }
            ]
        },
        dataBrowsers: {
            labels: [],
            datasets: [
                {
                    backgroundColor: ['#d7d6fe', '#fee4e1', '#c5f9f6', '#DD1B16'],
                    data: []
                }
            ]
        },
        dataLastPage: {
            labels: [],
            datasets: [
                {
                    backgroundColor: ['#d7d6fe', '#fee4e1', '#c5f9f6', '#DD1B16'],
                    data: []
                }
            ]
        },
        activeUsers: 0,
        totalNumber: 0,
        conversionRate: 0,
        popularTariffs: [],
        pages: []
    }),
    async mounted() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            const response = await axios.get(process.env.VUE_APP_APP_SERVICE_DOMAIN + '/analytics/1');
            const data = response.data;
            const basics = data.basic;
            this.dataCountry.labels = Object.keys(basics.countries);
            this.dataCountry.datasets[0].data = Object.values(basics.countries);
            this.dataCities.labels = Object.keys(basics.cities);
            this.dataCities.datasets[0].data = Object.values(basics.cities);
            this.dataDevices.labels = Object.keys(basics.devices);
            this.dataDevices.datasets[0].data = Object.values(basics.devices);
            this.dataOS.labels = Object.keys(basics.os);
            this.dataOS.datasets[0].data = Object.values(basics.os);
            this.dataBrowsers.labels = Object.keys(basics.browsers);
            this.dataBrowsers.datasets[0].data = Object.values(basics.browsers);
            this.activeUsers = data.active_users;
            this.conversionRate = data.conversion_rate;
            this.totalNumber = data.total_number_visits;
            this.popularTariffs = data.tariff_selected;
            this.dataLastPage.labels = Object.keys(data.last_page);
            this.dataLastPage.datasets[0].data = Object.values(data.last_page);
            this.pages = data.pages;
        }
    }
};
</script>